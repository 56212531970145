<template>
  <div class="content">
    <h1 class="title title--big title--color title--indent">Новости</h1>
    <div class="news-list">
      <router-link
        :to="{name: 'partner-news-detail', params: {slug: newsItem.slug}}"
        v-for="newsItem in news"
        :key="newsItem.id"
        class="news-list__item"
      >
        <div class="news-list__picture">
          <img :src="newsItem.img_advt_url" alt="news-preview">
        </div>
        <div class="news-list__content">
          <h2 class="title title--indent news-list__title">{{ newsItem.name }}</h2>
          <div class="news-list__info">
            <div class="news-list__detail">
              <img src="@/assets/img/calendar-icon.svg" alt="calendar-icon">
              <span>{{ newsItem.date }}</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsList',
  data() {
    return {
      news: [],
    };
  },
  mounted() {
    this.fetchNews();
  },
  methods: {
    fetchNews() {
      this.$store.dispatch('partner/news/GET_LIST')
        .then(response => {
          this.news = response.data.results;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.news-list__item
  display: flex
  margin-bottom: 20px
  padding-bottom: 20px
  border-bottom: 1px solid #E7EEF4
  text-decoration: none

  &:last-child
    margin-bottom: 0

  &:hover
    .news-list__title
      color: $color-theme

  +max-w($mobile_lg)
    flex-direction: column
    align-items: center

.news-list__picture
  flex-shrink: 0
  overflow: hidden
  width: 235px
  height: 160px
  margin-right: 30px
  border-radius: 5px

  img
    width: 100%
    height: 100%
    object-fit: cover

  +max-w($mobile_xl)
    margin-right: 15px

  +max-w($mobile_lg)
    margin-right: 0
    margin-bottom: 20px

.news-list__content
  display: flex
  flex-direction: column

  +max-w($mobile_lg)
    align-items: center

.news-list__info
  display: flex
  align-items: center

  +max-w($mobile_lg)
    text-align: center

.news-list__title
  margin-right: 0
  color: $color-text-base
  transition: color 0.3s

  +max-w($mobile_lg)
    text-align: center

.news-list__detail
  display: flex
  align-items: center
  margin-right: 20px
  color: #A5A5A7
  font-size: 16px

  &:last-child
    margin-right: 0

  img
    margin-right: 10px

.news-list__detail--color
  color: $color-theme
</style>